<template>
  <section class="footer">
    <div class="content">
      <span class="text">{{ $t('about') }}</span>
      <span class="text">{{ $t('contact') }}</span>
      <span class="text">
        <a href="http://beian.miit.gov.cn" target="_blank">浙ICP备12047051号-5</a>
      </span>
      <span>版权所有：杭州飞思十工业设计有限公司</span>
    </div>
    <div class="copyright">
      <span class="text">Copyright © 2021 Design Intellgence</span>
    </div>
  </section>
</template>

<script>
export default {
  name: 'footer',
  data() {
    return {};
  },
  components: {},
  watch: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped lang="less">
.footer {
  height: 7vw;
  background: #e4e4e4;
  padding: 0 14vw;
  .content {
    text-align: left;
    padding: 1.4vw 0 0.7vw;
    border-bottom: 1px solid #989899;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .text {
      margin-right: 5vw;
    }
  }
  .copyright {
    text-align: right;
  }
  .text {
    font-size: 1vw;
    color: #989899;
    a {
      text-decoration: none;
    }
  }
}
</style>
