<template>
  <div class="layout">
    <div class="menu-wrapper">
      <Menu />
    </div>
    <div class="content-wrapper">
      <!-- <keep-alive> -->
      <router-view class="view" :key="$route.path"> </router-view>
      <!-- </keep-alive> -->
    </div>

    <div class="footer-wrapper">
      <Footer />
    </div>
  </div>
</template>

<script>
import Menu from "./menu.vue";
import Footer from "./footer.vue";

export default {
  name: "layout",
  data() {
    return {};
  },
  components: {
    Menu,
    Footer,
  },
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.layout {
  .menu-wrapper,
  .content-wrapper {
    // margin: 0 200px;
    margin: 0 14vw;
  }
  .content-wrapper {
    min-height: 41vw;
  }
}
</style>
