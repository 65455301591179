<template>
  <section class="menu">
    <div class="bar">
      <div class="company-info">
        <!-- <div class="logo" @click="handleSelect('0')">
          <el-image fit="fill" :src="logoImage" alt=""></el-image>
        </div> -->
        <div class="tabs">
          <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
            <el-menu-item index="home">
              <div class="logo">
                <el-image fit="fill" :src="logoImage" alt=""></el-image>
              </div>
            </el-menu-item>
            <el-menu-item index="news"
              ><span>{{ $t('press') }}</span></el-menu-item
            >
            <el-menu-item index="rules"
              ><span>{{ $t('rules') }}</span></el-menu-item
            >
            <el-menu-item index="submission"
              ><span>{{ $t('submission') }}</span></el-menu-item
            >
          </el-menu>
        </div>
      </div>
      <div class="user-info">
        <span class="language" @click="switchLang()">{{ isChinese ? 'EN' : '中文' }}</span>
        <el-dropdown>
          <el-image class="account" fit="fill" :src="require('@/static/images/account.png')" alt=""></el-image>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="toUserCenter()">个人中心</el-dropdown-item>
            <el-dropdown-item @click.native="toLogin()">退出登陆</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- <Home /> -->
  </section>
</template>

<script>
import Vue from 'vue';
import { Menu, MenuItem } from 'element-ui';

Vue.component(Menu.name, Menu);
Vue.component(MenuItem.name, MenuItem);

export default {
  name: 'menu',
  components: {},
  computed: {
    isChinese() {
      return this.$store.state.isChinese;
    }
  },
  watch: {
    '$route.path'() {
      this.getActiveRoute();
    }
  },
  mounted() {
    this.getActiveRoute();
  },
  data() {
    return {
      activeIndex: '',
      logoImage: require('@/static/images/logo.png')
    };
  },
  methods: {
    getActiveRoute() {
      const routerVO = {
        '/home': 'home',
        '/news': 'news',
        '/rules': 'rules',
        '/submission': 'submission'
      };
      const key = this.$route.path.startsWith('/news') ? '/news' : this.$route.path;
      this.activeIndex = routerVO[key];
    },
    handleSelect(key) {
      this.$router.push(`/${key}`);
    },
    switchLang() {
      this.$store.dispatch('changeLanguage');
      this.$i18n.locale = this.$store.state.lang;
      this.logoImage = this.isChinese ? require('@/static/images/logo.png') : require('@/static/images/en-logo.png');
    },
    toUserCenter() {
      this.$router.push('/user/center');
    },
    toLogin() {
      this.$router.push('/login');
      localStorage.removeItem('token');
      localStorage.removeItem('userId');
    }
  }
};
</script>

<style lang="less" scoped>
.menu {
  .bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.2vw;
    width: 72vw;

    .company-info {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .logo {
        cursor: pointer;
        .el-image {
          width: 17vw;
        }
      }
      .tabs {
        .el-menu.el-menu--horizontal {
          border-bottom: unset;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        .el-menu--horizontal > .el-menu-item.is-active {
          border-bottom: unset;
          color: unset;
        }
        .el-menu--horizontal > .el-menu-item {
          border-bottom: unset;
          padding-left: 0px;
        }
        .el-menu-item {
          margin-right: 3vw;
          font-size: 1.25vw;
          font-weight: 600;
          color: #a5a5a5;
          &.is-active {
            color: #1a2043;
            span {
              border-bottom: 2px solid #1a2043;
            }
          }
        }
      }
    }
    .user-info {
      display: flex;
      align-items: center;
      justify-content: center;
      .language {
        font-size: 1.3vw;
        font-weight: 500;
        color: #1a2043;
        line-height: 25px;
      }
      .account {
        width: 2.1vw;
        margin-left: 0.7vw;
      }
    }
  }
}
</style>
